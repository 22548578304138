<template>
  <el-container class="app-container">

    <el-form ref="ruleForm" :disabled="fordisabled" :model="ruleForm" :rules="rules" label-width="150px">
      <el-form ref="ruleForm" :disabled="false" :model="ruleForm" :rules="rules" label-width="150px">
        <el-row :span="24" class="mb-3">
          <p>选择需要变更名称供应商</p>
          <el-col :span="24">
            <el-form-item label="供应商" label-width="45%" class="vendorColor" prop="Id">
              <el-select v-model="ruleForm.Id" filterable :loading="loading" :placeholder="$t('page.selectPlaceholder')" @change="isChange">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-header style="margin: 10px 0px; font-size: 20px;font-weight:700;">{{
        $t("title.BasicInformation")
      }}</el-header>
      <el-row class="row-flexed" :gutter="20">
        <el-col :span="8">
          <el-form-item label=" 供应商标识" prop="vendorIdentification">
            <el-input v-model="ruleForm.vendorIdentification" :placeholder="$t('page.inputPlaceholder')" :disabled="Boolean($route.query.id)" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.vendorCode')" prop="vendorCode">
            <el-input v-model="ruleForm.vendorCode" :placeholder="$t('page.inputPlaceholder')" :disabled="Boolean($route.query.id)" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.vendorName')" prop="vendorName">
            <el-input v-model="ruleForm.vendorName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.vendorFull')" prop="vendorFull">
            <el-input v-model="ruleForm.vendorFull" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商英文名称" prop="vendorNameEn">
            <el-input v-model="ruleForm.vendorNameEn" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.vendorSimple')" prop="vendorSimple">
            <el-input v-model="ruleForm.vendorSimple" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="$t('title.vendorType')" prop="vendorLevel">
            <el-select v-model="ruleForm.vendorLevel" filterable style="width:100%" :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_LEVEL')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商类型" prop="vendorType">
            <el-select
              v-model="ruleForm.vendorType"
              style="width:100%"
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              disabled
              @change="vendorTypeChange"
            >
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_TYPE')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="'three-row ' + $i18n.locale" :label="$t('title.enterpriseSize')" prop="enterpriseScale">
            <el-select v-model="ruleForm.enterpriseScale" filterable style="width:100%" :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in _getAllCommodityDict('ENTERPRISE_SCALE')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" :label="$t('title.Established')" prop="trCreateTime">
            <div class="block">
              <el-date-picker
                v-model="ruleForm.trCreateTime"
                style="width:100%"
                type="date"
                value-format="yyyy-MM-dd"
                :placeholder="$t('page.plzChooseDateTime')"
              />
            </div>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item :label="$t('title.buyer')" prop="purchasePersonnel">
            <el-select v-model="ruleForm.purchasePersonnel" style="width:100%" :placeholder="$t('page.selectPlaceholder')" filterable>
              <el-option
                v-for="item in _getAllCommodityDict('PURCHASE_PERSONNEL')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item :class="'three-row ' + $i18n.locale" :label="$t('title.taxIdentificationCode')" prop="trNumber">
            <el-input v-model="ruleForm.trNumber" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商管理工程师" prop="vendorManageEngineerFeishuUserId">
            <el-cascader
              ref="cascaderRef1"
              v-model="ruleForm.vendorManageEngineerFeishuUserId"
              style="width:100%"
              clearable
              :show-all-levels="false"
              filterable
              :options="tagData"
              :props=" {emitPath:false,checkStrictly: false, value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
              @change="(val)=>cascaderChange(val,'cascaderRef1')"
            >
              <template slot-scope="{ data }">
                {{ data.name }}
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购员" prop="purchaseFeishuUserId">
            <el-cascader
              ref="cascaderRef2"
              v-model="ruleForm.purchaseFeishuUserId"
              style="width:100%"
              filterable
              :show-all-levels="false"
              clearable
              :options="tagData"
              :props=" {emitPath:false,checkStrictly: false, value: 'feishuUserId', label: 'name', children: 'childList' }"
              @change="(val)=>cascaderChange(val,'cascaderRef2')"
            >
              <template slot-scope="{ data }">
                {{ data.name }}
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="技术工程师" prop="technologyEngineerFeishuUserId">
            <el-cascader
              ref="cascaderRef3"
              v-model="ruleForm.technologyEngineerFeishuUserId"
              style="width:100%"
              clearable
              :show-all-levels="false"
              filterable
              :options="tagData"
              :props=" {emitPath:false,checkStrictly: false, value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
              @change="(val)=>cascaderChange(val,'cascaderRef3')"
            >
              <template slot-scope="{ data }">
                {{ data.name }}
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开发工程师" prop="developEngineerFeishuUserId">
            <el-cascader
              ref="cascaderRef4"
              v-model="ruleForm.developEngineerFeishuUserId"
              style="width:100%"
              clearable
              :show-all-levels="false"
              :options="tagData"
              filterable
              :props=" {emitPath:false,checkStrictly: false, value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
              @change="(val)=>cascaderChange(val,'cascaderRef4')"
            >
              <template slot-scope="{ data }">
                {{ data.name }}
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="品质QA工程师" prop="qualityQaEngineerFeishuUserId">
            <el-cascader
              ref="cascaderRef5"
              v-model="ruleForm.qualityQaEngineerFeishuUserId"
              style="width:100%"
              clearable
              :show-all-levels="false"
              filterable
              :options="tagData"
              :props=" {checkStrictly: false, value: 'feishuUserId', label: 'name', children: 'childList' }"
              @change="(val)=>cascaderChange(val,'cascaderRef5')"
            >
              <template slot-scope="{ data }">
                {{ data.name }}
              </template>
            </el-cascader>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="品质QC工程师" prop="qualityQcEngineerFeishuUserId">
            <el-cascader
              ref="cascaderRef6"
              v-model="ruleForm.qualityQcEngineerFeishuUserId"
              style="width:100%"
              clearable
              :show-all-levels="false"
              filterable
              :options="tagData"
              :props=" {emitPath:false,checkStrictly: false, value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
              @change="(val)=>cascaderChange(val,'cascaderRef6')"
            >
              <template slot-scope="{ data }">
                {{ data.name }}
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="区域" prop="areaCode">
            <el-select v-model="ruleForm.areaCode" style="width:100%" :placeholder="$t('page.selectPlaceholder')" clearable>
              <el-option
                v-for="item in _getAllCommodityDict('AREA_CODE')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="送货方式" prop="deliveryMethod">
            <Select
              v-model="ruleForm.deliveryMethod"
              style="width:100%"
              :select-options="_getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
              :configuration="{ key: 'val', label: 'label', value: 'val' }"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收货地点" prop="receiptPlaceCode">
            <Select
              v-model="ruleForm.receiptPlaceCode"
              style="width:100%"
              api-key="warehouseList"
              clearable
              @responseData="val=>receiptPlaceList=val"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('page.status')" prop="useStatus">
            <el-radio-group v-model="ruleForm.useStatus">
              <el-radio :label="1">{{ $t("page.enabled") }}</el-radio>
              <el-radio :label="2">{{ $t("page.disable") }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-header style="margin: 10px 0px; font-size: 20px;font-weight:700;" class="mr-3">{{ $t("title.addressInformation") }}</el-header>
        </el-col>
        <i v-if="!fordisabled&&!ruleForm.bvaList.length" class="el-icon-plus" style="padding-top:15px;" @click="clickBtn()" />
      </el-row>
      <el-row :span="24">
        <el-form ref="ruleForm2" :disabled="fordisabled" :model="ruleForm" :rules="rules">
          <el-table
            :data="ruleForm.bvaList"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="addressTypeList"
              label="类型"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-select
                    v-model="scope.row.addressTypeList"
                    :placeholder="$t('page.selectPlaceholder')"
                    value-key="siteName"
                    multiple
                    filterable
                  >
                    <el-option v-for="ITEM in _getAllCommodityDict('VENDOR_ADDRESS_TYPE')" :key="ITEM.val" :label="ITEM.label" :value="ITEM.val" />
                  </el-select>
                  <el-form-item /></el-form-item></template>
            </el-table-column>
            <el-table-column
              prop="addressCoding"
              label="地址代号"
            >
              <template slot="header">
                <p>
                  地址代号
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.addressCoding'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['blur'] }"
                >
                  <el-input v-model="scope.row.addressCoding" :placeholder="$t('page.inputPlaceholder')" maxlength="100" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="国家"
              prop="stateId"
            >
              <template slot="header">
                <p>
                  国家
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.stateId'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['change'] }"
                >
                  <el-select v-model="scope.row.stateId" filterable clearable :placeholder="$t('page.selectPlaceholder')" :disabled="fordisabled" @change="Change(scope,$event,0)">
                    <el-option
                      v-for="item in state"
                      :key="item.id"
                      :label="item.stateName"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="省/州"
              prop="provinceId"
            >
              <template slot="header">
                <p>
                  省/州
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.provinceId'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['change'] }"
                >
                  <el-select v-model="scope.row.provinceId" filterable clearable :placeholder="$t('page.selectPlaceholder')" :disabled="fordisabled" @change="Change(scope,$event,1)">
                    <el-option
                      v-for="item in provinceArr[scope.$index]"
                      :key="item.id"
                      :disabled="scope.row.stateId!==1"
                      :label="item.provinceName"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="城市"
              prop="cityId"
            >
              <template slot="header">
                <p>
                  城市
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.cityId'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['change'] }"
                >
                  <el-select v-model="scope.row.cityId" filterable clearable :placeholder="$t('page.selectPlaceholder')" :disabled="fordisabled" @change="Change(scope,$event,2)" @visible-change="visibleChange(scope,0)">
                    <el-option
                      v-for="item in cityArr[scope.$index]"
                      :key="item.id"
                      :disabled="scope.row.stateId!==1"
                      :label="item.cityName"
                      :value="item.id"
                    >{{ item.cityName }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="区县"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-select v-model="scope.row.countyId" filterable clearable :placeholder="$t('page.selectPlaceholder')" :disabled="fordisabled" @visible-change="visibleChange(scope,3)">
                    <el-option
                      v-for="item in countyArr[scope.$index]"
                      :key="item.id"
                      :disabled="scope.row.stateId!==1"
                      :label="item.countyName"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="详细地址"
              prop="fullAddress"
            >
              <template slot="header">
                <p>
                  详细地址
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.fullAddress'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['blur'] }"
                >
                  <el-input v-model="scope.row.fullAddress" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="邮编"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input v-model="scope.row.postcode" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="地址联系人"
              prop="addressPerson"
            >
              <template slot="header">
                <p>
                  地址联系人
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.addressPerson'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['blur'] }"
                >
                  <el-input v-model="scope.row.addressPerson" :placeholder="$t('page.inputPlaceholder')" maxlength="50" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="联系电话"
              prop="contactNumber"
            >
              <template slot="header">
                <p>
                  联系电话
                  <span style="color:red;font-size:16px;">*</span>
                  <el-popover trigger="hover" placement="top-start">
                    <span>类型包含退换货地址 / 发货地址时，必填</span>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'bvaList.' + scope.$index + '.contactNumber'"
                  :rules="{ required: scope.row.addressTypeList.includes('3') ||scope.row.addressTypeList.includes('4'), message: $t('page.required'), trigger: ['blur'] }"
                >
                  <el-input v-model="scope.row.contactNumber" :placeholder="$t('page.inputPlaceholder')" maxlength="50" />
                </el-form-item>
              </template>
            </el-table-column>
            <template v-if="!fordisabled">
              <el-table-column
                width="80"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-form-item>
                    <div v-if="fordisabled !== true">
                      <i
                        class="el-icon-remove-outline"
                        style="margin-right:10px;"
                        @click="deleteBtn(scope.row,scope.$index)"
                      />
                      <i
                        v-if="scope.$index+1==ruleForm.bvaList.length"
                        class="el-icon-circle-plus-outline"
                        @click="clickBtn"
                      />
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </el-form>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-header style="margin: 10px 0px; font-size: 20px;font-weight:700;" class="mr-3">{{ $t("title.contactInformation") }}</el-header>
        </el-col>
        <i v-if="!fordisabled&&!ruleForm.bvcList.length" class="el-icon-plus" style="padding-top:15px;" @click="clickBtnLinkman()" />
      </el-row>
      <el-row :span="24">
        <el-table
          :data="ruleForm.bvcList"
          border
          style="width: 100%"
          class="bvcList"
        >
          <el-table-column
            prop="name"
            label="姓名"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.linkman" :placeholder="$t('page.inputPlaceholder')" />
            </template>
          </el-table-column>
          <el-table-column
            label="角色"
          >
            <template slot-scope="scope">
              <el-select v-model="scope.row.role" clearable>
                <el-option
                  v-for="ITEM in _getAllCommodityDict('VENDOR_CONTACKS_ROLE')"
                  :key="ITEM.val"
                  :value="ITEM.val"
                  :label="ITEM.label"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="手机号"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.mobile" :placeholder="$t('page.inputPlaceholder')" />
            </template>
          </el-table-column>
          <el-table-column
            label="联系电话"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.telephone" :placeholder="$t('page.inputPlaceholder')" />
            </template>
          </el-table-column>
          <el-table-column
            label="邮箱"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.email" />
            </template>
          </el-table-column>
          <el-table-column
            label="传真"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.fax" :placeholder="$t('page.inputPlaceholder')" />
            </template>
          </el-table-column>
          <el-table-column
            label="微信"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.wechat" :placeholder="$t('page.inputPlaceholder')" />
            </template>
          </el-table-column>
          <el-table-column
            label="QQ"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.qq" :placeholder="$t('page.inputPlaceholder')" />
            </template>
          </el-table-column>
          <el-table-column
            width="80"
            label="操作"
          >
            <template slot-scope="scope">
              <div v-if="fordisabled !== true">
                <i
                  class="el-icon-remove-outline"
                  style="margin-right:10px;"
                  @click="deleteLinkman(scope.row,scope.$index)"
                />
                <i
                  v-if="scope.$index+1==ruleForm.bvcList.length"
                  class="el-icon-circle-plus-outline"
                  @click="clickBtnLinkman"
                />
              </div>

            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-row>
        <el-col :span="4">
          <el-header style="margin: 10px 0px; font-size: 20px;font-weight:700;" class="mr-3">账户信息</el-header>
        </el-col>
        <i v-if="!fordisabled&&!ruleForm.vendorAccountList.length" class="el-icon-plus" style="padding-top:15px;" @click="clickBtnAccount()" />
      </el-row>
      <el-row :span="24">
        <el-form ref="ruleForm1" :disabled="fordisabled" :rules="rules" :model="ruleForm">
          <el-table
            :data="ruleForm.vendorAccountList"
            border
            style="width: 100%"
            class="bvcList"
          >
            <el-table-column
              prop="accountType"
              label="账户类型"
            >
              <template slot="header">
                <p>
                  账户类型
                  <span style="color:red;font-size:16px;">*</span>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'vendorAccountList.' + scope.$index + '.accountType'"
                  :rules="rules.accountType"
                >
                  <el-select v-model="scope.row.accountType" :placeholder="$t('page.selectPlaceholder')">
                    <el-option
                      v-for="item in _getAllCommodityDict('VENDOR_ACCOUNT_TYPE')"
                      :key="item.val"
                      :value="item.val"
                      :label="item.label"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="开户名称"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input v-model="scope.row.accountName" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="开户行名称"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input v-model="scope.row.bankName" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="行号"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input v-model="scope.row.bankNumber" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="开户行地址"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input v-model="scope.row.bankAddress" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="accountHolderAddress"
              label="开户人地址"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'vendorAccountList.' + scope.$index + '.accountHolderAddress'"
                  :rules="[{ required: scope.row.accountType === '2', message: $t('page.required'), trigger: ['blur'] }]"
                >
                  <el-input v-model="scope.row.accountHolderAddress" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="cardNo"
              label="银行账号"
            >
              <template slot="header">
                <p>
                  银行账号
                  <span style="color:red;font-size:16px;">*</span>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'vendorAccountList.' + scope.$index + '.cardNo'"
                  :rules="rules.cardNo"
                >
                  <el-input v-model="scope.row.cardNo" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="receivePayBankName"
              :label="$t('title.beneficiaryBankName')"
            >
              <template slot="header">
                <p>
                  {{ $t('title.beneficiaryBankName') }}
                  <span style="color:red;font-size:16px;">*</span>
                </p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'vendorAccountList.' + scope.$index + '.receivePayBankName'"
                  :rules="rules.receivePayBankName"
                >
                  <el-input v-model="scope.row.receivePayBankName" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="币种"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'vendorAccountList.' + scope.$index + '.currencyType'"
                  :rules="rules.currencyType"
                >
                  <el-select v-model="scope.row.currencyType" filterable :placeholder="$t('page.selectPlaceholder')">
                    <el-option label="CNY" value="CNY" />
                    <el-option label="USD" value="USD" />
                  </el-select>
                  <!-- <el-input v-model="scope.row.currencyType" :placeholder="$t('page.inputPlaceholder')" /> -->
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="Swift Code"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'vendorAccountList.' + scope.$index + '.swiftCode'"
                  :rules="{ required: scope.row.accountType==='2', message: $t('page.required'), trigger: ['blur'] }"
                >
                  <el-input v-model.trim="scope.row.swiftCode" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column
              width="80"
              label="操作"
            >
              <template slot-scope="scope">
                <div v-if="fordisabled !== true">
                  <i
                    class="el-icon-remove-outline"
                    style="margin-right:10px;"
                    @click="deleteAccount(scope.row,scope.$index)"
                  />
                  <i
                    v-if="scope.$index+1==ruleForm.vendorAccountList.length"
                    class="el-icon-circle-plus-outline"
                    @click="clickBtnAccount"
                  />
                </div>

              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-row>

      <el-header style="margin: 10px 0px; font-size: 20px;font-weight:700;">{{ $t("title.paymentInformation") }}</el-header>
      <el-row class="row-flexed" :gutter="20">
        <el-col :span="8">
          <el-form-item v-if="ruleForm.vendorType=='4'" key="1" :label="$t('title.contractingBody')" prop="cargoOwnerName">
            <el-select v-model="ruleForm.cargoOwnerName" style="width:100%" value-key="id" placeholder="请选择" filterable :disabled="Boolean(ruleForm.vendorType!=='4')" @change="changeCargo">
              <el-option
                v-for="item in cargoOwnerNameList"
                :key="item.cargoOwnerName"
                :label="item.cargoOwnerName"
                :value="item.cargoOwnerName"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else key="2" :label="$t('title.contractingBody')">
            <el-select v-model="ruleForm.cargoOwnerName" style="width:100%" value-key="id" placeholder="请选择" filterable :disabled="Boolean(ruleForm.vendorType!=='4')" @change="changeCargo">
              <el-option
                v-for="item in cargoOwnerNameList"
                :key="item.cargoOwnerName"
                :label="item.cargoOwnerName"
                :value="item.cargoOwnerName"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.minimumNum')" prop="minimumNum">
            <el-input v-model="ruleForm.minimumNum" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.cargoSrc')" prop="cargoSrc">
            <el-input v-model="ruleForm.cargoSrc" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.taxRates')" prop="taxRates">
            <el-input v-model="ruleForm.taxRates" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.quotationCurrency')" prop="offerCurrency">
            <el-select v-model="ruleForm.offerCurrency" style="width:100%" filterable :placeholder="$t('page.selectPlaceholder')" multiple clearable>
              <el-option label="CNY" value="CNY" />
              <el-option label="USD" value="USD" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收款币种" prop="receivePayCurrency">
            <el-select v-model="ruleForm.receivePayCurrency" style="width:100%" filterable :placeholder="$t('page.selectPlaceholder')" multiple clearable>
              <el-option label="CNY" value="CNY" />
              <el-option label="USD" value="USD" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="结算方式" prop="settlementMethod">
            <el-select v-model="ruleForm.settlementMethod" style="width:100%" filterable :placeholder="$t('page.selectPlaceholder')" clearable>
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_SETTLEMENT_METHOD')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="结算方式描述" prop="settlementMethodDesc">
            <el-input v-model="ruleForm.settlementMethodDesc" :placeholder="$t('page.inputPlaceholder')" type="textarea" maxlength="400" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="本位币种">
            <el-select v-model="ruleForm.standardCurrency" style="width:100%" filterable :placeholder="$t('page.selectPlaceholder')" clearable>
              <el-option label="CNY" value="CNY" />
              <el-option label="USD" value="USD" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row-flexed" :gutter="20" />
      <el-row :gutter="20" class="row-flexed">
        <el-col :span="8">
          <el-form-item label="订金比例" prop="depositRate">
            <template>
              <el-input
                v-model="ruleForm.depositRate"
                :placeholder="$t('page.inputPlaceholder')"
                oninput="value=value.replace(/[^\d.]/g,'')"
                @blur="depositRateInput"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="订金账期" prop="depositAccountPeriod" :required="Boolean(Number(ruleForm.depositRate))">
            <template>
              <el-input
                v-model="ruleForm.depositAccountPeriod"
                placeholder="请输入整数"
                type="text"
                @blur="/^-?[1-9]\d*$|^0$/.test(ruleForm.depositAccountPeriod)?'':ruleForm.depositAccountPeriod=''"
              >
                <template slot="append">日</template>
              </el-input>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-flexed" :gutter="20">
        <el-col :span="8">
          <el-form-item label="首款比例" prop="firstRate">
            <template>
              <el-input
                v-model="ruleForm.firstRate"
                :placeholder="$t('page.inputPlaceholder')"
                oninput="value=value.replace(/[^\d.]/g,'')"
                @blur="firstRateInput"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="首款账期" prop="firstAccountPeriod" :required="Boolean(Number(ruleForm.firstRate))">
            <template>
              <el-input
                v-model="ruleForm.firstAccountPeriod"
                placeholder="请输入整数"
                @blur="/^-?[1-9]\d*$|^0$/.test(ruleForm.firstAccountPeriod)?'':ruleForm.firstAccountPeriod=''"
              >
                <template slot="append">日</template>
              </el-input>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="row-flexed">
        <el-col :span="8">
          <el-form-item label="尾款比例" prop="lastRate">
            <template>
              <el-input
                v-model="ruleForm.lastRate"
                :placeholder="$t('page.inputPlaceholder')"
                oninput="value=value.replace(/[^\d.]/g,'')"
                @blur="lastRateInput"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="尾款账期" prop="lastAccountPeriod" :required="Boolean(Number(ruleForm.lastRate))">
            <template>
              <el-input
                v-model="ruleForm.lastAccountPeriod"
                placeholder="请输入整数"
                @blur="/^-?[1-9]\d*$|^0$/.test(ruleForm.lastAccountPeriod)?'':ruleForm.lastAccountPeriod=''"
              >
                <template slot="append">日</template>
              </el-input>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-flexed" :gutter="20">
        <el-col :span="8">
          <el-form-item ref="brc" :class="$i18n.locale" :label="$t('title.brcfilelist')" prop="brc">
            <el-upload
              ref="brc1"
              :class="{ hide: hideUpload1 }"
              :action="action"
              :headers="uploadHeaders"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="brchandleSuccess"
              :on-remove="brchandleRemove"
              :on-change="brchandleFileChange"
              :file-list="brcfilelist"
            >
              <img v-if="ruleForm.brc && ruleForm.brc !== null" width="100%" height="100%" :src="ruleForm.brc" alt="">
              <i v-else class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="ruleForm.brc" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item ref="lfoa" :label="$t('title.lfoafilelist')" prop="lfoa">
            <el-upload
              ref="lfoa1"
              :class="{ hide: hideUpload2 }"
              :headers="uploadHeaders"
              :action="action"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="lfoahandleSuccess"
              :on-remove="lfoahandleRemove"
              :on-change="lfoahandleFileChange"
              :file-list="lfoafilelist"
            >
              <img v-if="ruleForm.lfoa && ruleForm.brc !== null" width="100%" height="100%" :src="ruleForm.lfoa" alt="">
              <i v-else class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="ruleForm.lfoa" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item ref="pa" :class="$i18n.locale" :label="$t('title.pafilelist')" prop="pa">
            <el-upload
              ref="pa1"
              :class="{ hide: hideUpload3 }"
              :headers="uploadHeaders"
              :action="action"
              list-type="picture-card"
              :on-success="pahandleSuccess"
              :on-remove="pahandleRemove"
              :on-change="pahandleFileChange"
              :file-list="pafilelist"
            >
              <img v-if="ruleForm.pa && ruleForm.pa !== null" width="100%" height="100%" :src="ruleForm.pa" alt="">
              <i v-else class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="ruleForm.pa" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
      </el-row>

      <el-header style="height:40px;margin: 90px 0px 10px 0; font-size: 20px;font-weight:700;">关务信息</el-header>
      <el-row>
        <el-col :span="16">
          <el-form-item label="报关方式">
            <el-radio-group v-model="ruleForm.customsDeclarationType">

              <el-radio
                v-for="(item,index) in _getAllCommodityDict('CUSTOMS_DECLARATION_TYPE')"
                :key="index"
                :label="item.val"
                :value="item.val"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24" class="mb-3">
        <p style="color:red">供应商变更盖章确认附件* <span style="color:red;margin-left:20%">请上传jpg/png/jpeg格式图片或pdf文件，大小不超过5M</span></p>
        <el-col :span="24">
          <el-form-item ref="vendorChangeStampFileUrl" label-width="42%" prop="vendorChangeStampFileUrl">
            <el-upload
              ref="stampFileUrl1"
              :class="{ hide: hideUploadUrl1 }"
              :headers="uploadHeaders"
              :action="action"
              list-type="picture-card"
              :on-success="stampFileUrlHandleSuccess"
              :on-remove="stampFileUrlHandleRemove"
              :on-change="stampFileUrlHandleFileChange"
              :file-list="UrlFileList"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="ruleForm.vendorChangeStampFileUrl && ruleForm.vendorChangeStampFileUrl !== null" width="100%" height="100%" :src="ruleForm.vendorChangeStampFileUrl" alt="">
              <i v-else class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="ruleForm.vendorChangeStampFileUrl" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="grid-content" type="flex" justify="center">
        <div style="margin-right:30px">
          <el-button :disabled="fordisabled" :loading="submitLoading" type="primary" @click="handleClick">{{ $t("title.submit") }}</el-button>
          <el-button @click="goBack">返回</el-button>
          <!-- <go-back margintop /> -->

        </div>

      </el-row>
    </el-form>
    <!-- <go-back margintop /> -->

    <ShowPhoto v-model="photoVisible" :url="url" />
    <!-- <ShowPhoto v-if="$route.query.disabled" :visible="photoVisible" :url="url" @closeClick="()=>{photoVisible=false}" /> -->
  </el-container>
</template>

<script>
import ShowPhoto from '@/components/ImageView'
import { getcargoownerList } from '@/api/scm-api'
import { getAllDepartmentInfo, baseVendorSubmit, baseVendorDetail, getSelectPending, getVendorSelect } from '@/api/blurbInformation.js'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryStateList, queryProvinceList, queryCityList, queryCountyList } from '@/api/listSelection'
import { deepClone } from '@/utils'
import Select from '@/components/Selection'
export default {
  components: {
    ShowPhoto, Select
  },
  mixins: [commodityInfoDict],
  data() {
    const vendorCoded = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('page.required')))
      } else {
        const reg = /^[a-zA-Z0-9]{2}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('限制2位字母数字组合'))
        }
      }
    }
    const vendorSimpled = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('page.required')))
      } else {
        const reg = /^[a-zA-Z0-9]{4}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('限制4位字母数字组合'))
        }
      }
    }
    const checkBankNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('page.required')))
      } else {
        // 可输入15-30位数字
        const reg = /^([0-9]{0})(\d{2,29})$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error(this.$t('page.IncorrectFormat')))
        }
      }
    }
    const tableInputTrim = (rule, value, callback) => {
      if (value.trim() === '') {
        return callback(new Error(this.$t('page.required')))
      } else {
        callback()
      }
    }
    const SupplierId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('page.required')))
      } else {
        const reg = /^[^\u4e00-\u9fa5]{0,}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('限制不能输入中文'))
        }
      }
    }
    const depositAccountPeriod = (rule, value, callback) => {
      if (!value && Number(this.ruleForm.depositRate)) {
        return callback(new Error(this.$t('page.required')))
      } else {
        callback()
      }
    }
    const firstAccountPeriod = (rule, value, callback) => {
      if (!value && Number(this.ruleForm.firstRate)) {
        return callback(new Error(this.$t('page.required')))
      } else {
        callback()
      }
    }
    const lastAccountPeriod = (rule, value, callback) => {
      if (!value && Number(this.ruleForm.lastRate)) {
        return callback(new Error(this.$t('page.required')))
      } else {
        callback()
      }
    }

    return {
      receiptPlaceList: [],
      tagData: [],
      url: '',
      submitLoading: false,
      photoVisible: false,
      countyArr: [],
      stateArr: [],
      provinceArr: [],
      cityArr: [],
      state: [],
      province: [],
      city: [],
      county: [],
      isChina: true,
      cargoOwnerNameList: [],
      fordisabled: false, // 禁用文本框
      formAddress: [], // 定义一个标识 接受参数值
      hideUpload1: false,
      action: process.env.VUE_APP_UPLOAD_API,
      hideUpload2: false,
      hideUpload3: false,
      limitCount1: 1,
      limitCount2: 1,
      limitCount3: 1,
      limitCountUrl1: 1,
      hideUploadUrl1: false,
      dialogVisible: false,
      dialogImageUrl: '',
      vendorId: '',
      // 图片列表（用于在上传组件中回显图片）
      brcfilelist: [], //  上传用文件
      lfoafilelist: [], //  上传用文件
      pafilelist: [], //  上传用文件
      UrlFileList: [],
      labelPosition: 'right',
      ruleForm: {
        vendorNameEn: '',
        deliveryMethod: '',
        receiptPlaceCode: '',
        vendorIdentification: '',
        Id: '',
        settlementMethod: '',
        settlementMethodDesc: '',
        vendorLevel: '',
        cargoOwnerCode: '',
        trNumber: '',
        vendorCode: '',
        vendorName: '',
        vendorFull: '',
        vendorSimple: '',
        vendorType: '4',
        enterpriseScale: '',
        trCreateTime: '',
        purchasePersonnel: '',
        useStatus: 1,
        standardCurrency: '',
        bvaList: [
          {
            addressTypeList: '',
            stateId: '',
            provinceId: '',
            cityId: '',
            countyId: '',
            fullAddress: '',
            postcode: '',
            contactNumber: '',
            addressPerson: ''
          }
        ],
        bvcList: [
          {
            linkman: '',
            role: '',
            mobile: '',
            telephone: '',
            email: '',
            fax: '',
            wechat: '',
            qq: ''
          }
        ],
        vendorAccountList: [{
          accountHolderAddress: '',
          accountName: '',
          accountType: '',
          bankAddress: '',
          bankName: '',
          bankNumber: '',
          currencyType: '',
          swiftCode: '',
          receivePayBankName: ''
        }],
        cargoOwnerName: '',
        minimumNum: '',
        cargoSrc: '',
        vendorManageEngineerFeishuUserId: '',
        purchaseFeishuUserId: '',
        technologyEngineerFeishuUserId: '',
        developEngineerFeishuUserId: '',
        // qualityQaEngineerFeishuUserId: '',
        qualityQcEngineerFeishuUserId: '',
        areaCode: '',
        taxRates: '',
        receivePayCurrency: [],
        offerCurrency: [],
        depositRate: '',
        depositAccountPeriod: '',
        firstRate: '',
        firstAccountPeriod: '',
        lastRate: '',
        lastAccountPeriod: '',
        lfoa: '', // 开户许可
        pa: '', // 采购协议
        brc: '', // 三证合一
        vendorChangeStampFileUrl: '',
        customsDeclarationType: ''
      },
      aaa: '',
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      vendorOptions: [],
      loading: false,
      rules: {
        deliveryMethod: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        receiptPlaceCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        id: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        vendorChangeStampFileUrl: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        accountType: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        swiftCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }, { validator: tableInputTrim, message: this.$t('page.required'), trigger: 'blur' }],
        cardNo: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }, { required: true, validator: checkBankNum, trigger: ['blur'] }, { validator: tableInputTrim, message: this.$t('page.required'), trigger: 'blur' }],
        receivePayBankName: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }, { validator: tableInputTrim, message: this.$t('page.required'), trigger: 'blur' }],
        currencyType: [{ required: true, message: this.$t('page.required'), trigger: ['change'] }],
        swifbankNumbertCode: [{ validator: depositAccountPeriod, message: this.$t('page.required'), trigger: ['blur'] }, { validator: tableInputTrim, message: this.$t('page.required'), trigger: 'blur' }],
        vendorCode: [{ required: true, validator: (this.$route.query.id) ? '' : vendorCoded, trigger: ['blur', 'change'] }],
        vendorFull: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        vendorSimple: [{ required: true, validator: (this.$route.query.id) ? '' : vendorSimpled, trigger: ['blur', 'change'] }],
        vendorName: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        vendorType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        vendorLevel: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        enterpriseScale: [{ required: true, message: this.$t('page.required'), trigger: ['change'] }],
        trCreateTime: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        taxRates: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        depositAccountPeriod: [{ validator: depositAccountPeriod, message: this.$t('page.required'), trigger: ['blur'] }],
        lastAccountPeriod: [{ validator: lastAccountPeriod, message: this.$t('page.required'), trigger: ['blur'] }],
        firstAccountPeriod: [{ validator: firstAccountPeriod, message: this.$t('page.required'), trigger: ['blur'] }],
        depositRate: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        lastRate: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        firstRate: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        cargoOwnerName: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        settlementMethod: [{ required: true, message: this.$t('page.required'), trigger: ['change'] }],
        purchasePersonnel: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        useStatus: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        minimumNum: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        offerCurrency: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        receivePayCurrency: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        brc: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        lfoa: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        pa: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        trNumber: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        settlementMethodDesc: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        vendorManageEngineerFeishuUserId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        purchaseFeishuUserId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        technologyEngineerFeishuUserId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        developEngineerFeishuUserId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        // qualityQaEngineerFeishuUserId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        qualityQcEngineerFeishuUserId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        areaCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        vendorIdentification: [{ required: true, validator: SupplierId, trigger: 'blur' }]

      }
    }
  },
  computed: {
    Parameter() {
      const obj = deepClone(this.ruleForm)
      const { deliveryMethod, areaCode, vendorChangeStampFileUrl, vendorIdentification, receiptPlaceCode,
        developEngineerFeishuUserId, qualityQcEngineerFeishuUserId, vendorManageEngineerFeishuUserId, purchaseFeishuUserId, technologyEngineerFeishuUserId, cargoOwnerName, minimumNum, cargoSrc, taxRates, offerCurrency, receivePayCurrency, settlementMethod, settlementMethodDesc, standardCurrency, depositRate, depositAccountPeriod, firstRate, firstAccountPeriod, lastRate, lastAccountPeriod, brc, lfoa, pa } = obj
      const { useStatus, trNumber, purchasePersonnel, vendorCode, vendorName, vendorFull, vendorSimple, vendorLevel, vendorType, trCreateTime, enterpriseScale } = obj
      const payment = Object.assign({}, { cargoOwnerName, minimumNum, cargoSrc, taxRates, offerCurrency: offerCurrency && offerCurrency.toString(), receivePayCurrency: receivePayCurrency && receivePayCurrency.toString(), settlementMethod, settlementMethodDesc, standardCurrency, depositRate, depositAccountPeriod, firstRate, firstAccountPeriod, lastRate, lastAccountPeriod, brc, lfoa, pa })
      const receiptPlaceName = this.receiptPlaceList.find(item => item.sourceWarehouseCode === receiptPlaceCode)?.warehouseName
      const basic = Object.assign({}, { deliveryMethod, receiptPlaceCode, receiptPlaceName, areaCode, vendorIdentification, vendorChangeStampFileUrl, useStatus, trNumber, purchasePersonnel, vendorCode, vendorName, vendorFull, vendorSimple, vendorLevel, vendorType, enterpriseScale: enterpriseScale && enterpriseScale.toString(), trCreateTime })
      const { bvaList = [], bvcList = [], vendorAccountList = [] } = obj
      let bva = []
      let bvc = []
      let acc = []
      const VendorPerson = Object.assign({}, { purchaseFeishuUserId: typeof (purchaseFeishuUserId) === 'string' ? purchaseFeishuUserId : purchaseFeishuUserId[purchaseFeishuUserId.length - 1] })
      const purchaseName = this.findItemById(this.tagData, VendorPerson.purchaseFeishuUserId)
      const dataObj = { purchaseName: purchaseName.name }
      obj.baseVendorPerson = Object.assign({ ...VendorPerson, ...dataObj })
      // 处理多选
      const vendorRoleList = []
      vendorManageEngineerFeishuUserId.map(item => {
        const id = typeof (item) === 'string' ? item : item[item.length - 1]
        const feishuUserName = this.findItemById(this.tagData, id)?.name
        const roleData = Object.assign({ feishuUserName, feishuUserId: id, vendorRoleType: 'VENDOR_MANAGE_ENGINEER' })
        vendorRoleList.push(roleData)
      })
      technologyEngineerFeishuUserId.map(item => {
        const id = typeof (item) === 'string' ? item : item[item.length - 1]
        const feishuUserName = this.findItemById(this.tagData, id)?.name
        const roleData = Object.assign({ feishuUserName, feishuUserId: id, vendorRoleType: 'TECHNOLOGY_ENGINEER' })
        vendorRoleList.push(roleData)
      })
      developEngineerFeishuUserId.map(item => {
        const id = typeof (item) === 'string' ? item : item[item.length - 1]
        const feishuUserName = this.findItemById(this.tagData, id)?.name
        const roleData = Object.assign({ feishuUserName, feishuUserId: id, vendorRoleType: 'DEVELOP_ENGINEER' })
        vendorRoleList.push(roleData)
      })
      qualityQcEngineerFeishuUserId.map(item => {
        const id = typeof (item) === 'string' ? item : item[item.length - 1]
        const feishuUserName = this.findItemById(this.tagData, id)?.name
        const roleData = Object.assign({ feishuUserName, feishuUserId: id, vendorRoleType: 'QUALITY_QC_ENGINEER' })
        vendorRoleList.push(roleData)
      })

      // 将地址信息的类型数组转换为对象
      bvaList.map(item => {
        const arr = []
        item.addressTypeList.map((item) => {
          arr.push({
            addressTypeDict: item
          })
        })
        item.addressTypeList = arr
      })
      bva = bvaList && bvaList.length && bvaList.filter(item => item.stateId || item.addressTypeList || item.countyId || item.provinceId || item.cityId || item.fullAddress || item.postcode || item.contactNumber || item.addressPerson) || []
      bvc = bvcList && bvcList.length && bvcList.filter(item => item.vendorId || item.linkman || item.role || item.email || item.mobile || item.telephone || item.fax || item.wechat || item.qq) || []
      acc = vendorAccountList && vendorAccountList.length && vendorAccountList.filter(item => item.receivePayBankName || item.accountHolderAddress || item.accountName || item.accountType || item.bankAddress || item.bankName || item.bankName || item.bankNumber || item.currencyType || item.swiftCode) || []
      delete obj.offerCurrency
      delete obj.receivePayCurrency
      delete obj.bvaList
      delete obj.bvcList
      delete obj.vendorAccountList
      delete obj.vendorManageEngineerFeishuUserId
      delete obj.developEngineerFeishuUserId
      // delete obj.qualityQaEngineerFeishuUserId
      delete obj.purchaseFeishuUserId
      delete obj.qualityQcEngineerFeishuUserId
      delete obj.technologyEngineerFeishuUserId
      return Object.assign({}, { basic, payment, baseVendorPerson: obj.baseVendorPerson }, { address: bva, contacts: bvc, account: acc, id: this.vendorId, vendorRoleList })
    }
  },
  watch: {

  },
  created() {
    if (this.$route.query.id) {
      this.queryProvinceList()
      this.queryCityList()
      this.queryCountyList()
    }
  },
  async mounted() {
    // 查看时 禁用for
    if (!this.ruleForm.Id) {
      this.fordisabled = true
    }
    this._getcargoownerList()
    const { datas } = await queryStateList()
    this.state = datas
    // this._queryVendorId()
    this._getVendorSelect({ useStatus: 1 })
    this.queryProvinceList()
    this.queryCityList()
    this.queryCountyList()
    this._getAllDepartmentInfo()
  },
  methods: {
    // 获取树结构数据
    async _getAllDepartmentInfo() {
      const { datas } = await getAllDepartmentInfo({ cacheFlag: true })
      this.tagData = datas
      this.getDataTree(this.tagData)
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return []
      data.map(item => {
        Object.assign(item, {
          disabled: (item.childList == null || item.childList.length < 1) && item.departmentId !== null,
          feishuUserId: item.feishuUserId || Math.random().toString()
        })
        this.getDataTree(item.childList)
      })
    },
    findItemById(list, feishuUserId) {
      let res = list.find((item) => item.feishuUserId === feishuUserId)
      if (res) {
        return res
      } else {
        for (let i = 0; i < list.length; i++) {
          if (
            list[i].childList instanceof Array && list[i].childList.length > 0
          ) {
            res = this.findItemById(list[i].childList, feishuUserId)
            if (res) return res
          }
        }
        return null
      }
    },
    vendorTypeChange() {
      this.ruleForm.cargoOwnerCode = ''
      this.ruleForm.cargoOwnerName = ''
    },
    showImg(url) {
      this.photoVisible = true
      this.url = url
    },
    changeCargo(val) {
      const { cargoOwnerCode } = this.cargoOwnerNameList.find(item => item.cargoOwnerName === val)
      this.ruleForm.cargoOwnerCode = cargoOwnerCode
    },
    visibleChange({ row, $index }, index) {
      if (index === 0) {
        this._queryCityList(row.provinceId, $index)
      }
      if (index === 1) {
        this._queryCountyList(row.cityId, $index)
      }
    },
    Change({ row, $index }, val, index) {
      if (index === 0) {
        this._queryProvinceList(val, $index)
        row.provinceId = ''
        row.cityId = ''
        row.countyId = ''
        row.fullAddress = ''
        row.postcode = ''
      }
      if (index === 1) {
        this._queryCityList(val, $index)
        row.cityId = ''
        row.countyId = ''
        row.fullAddress = ''
        row.postcode = ''
      }
      if (index === 2) {
        this._queryCountyList(val, $index)
        row.countyId = ''
        row.fullAddress = ''
        row.postcode = ''
      }
    },
    deleteBtn(item, index) {
      this.ruleForm.bvaList && this.ruleForm.bvaList.splice(index, 1)
    },
    clickBtn() {
      this.ruleForm.bvaList.push({
        addressTypeList: '',
        stateId: '',
        provinceId: '',
        cityId: '',
        countyId: '',
        fullAddress: '',
        postcode: '',
        addressPerson: '',
        contactNumber: ''
      })
    },
    clearImage() {
      this.$refs.lfoa1.clearFiles()
      this.$refs.brc1.clearFiles()
      this.$refs.pa1.clearFiles()
      this.$refs.stampFileUrl1.clearFiles()
      this.hideUpload1 = false
      this.hideUpload2 = false
      this.hideUpload3 = false
      this.hideUploadUrl1 = false
      this.ruleForm.brc = ''
      this.ruleForm.pa = ''
      this.ruleForm.lfoa = ''
      this.ruleForm.vendorChangeStampFileUrl = ''
    },
    async isChange(item) {
      this.vendorId = item
      if (item) {
        this.fordisabled = false
        const { datas: vendorDta } = await getSelectPending({ vendorId: item })
        // const { datas } = await vendorOfferSelect({ vendorId: item })
        // datas === false &&
        if (vendorDta === false) {
          this.$refs.vendorChangeStampFileUrl.clearValidate()
          this.ruleForm.vendorChangeStampFileUrl = ''
          this.clearImage()
          this._baseVendorDetail({ vendorId: this.ruleForm.Id })
          this.fordisabled = false
        } else if (vendorDta === true) {
          this.$message({
            type: 'error',
            message: '已有变更审核中'
          })
          this.fordisabled = true
          this.$refs.ruleForm.resetFields()
          this.$refs.ruleForm1.resetFields()
          this.clearImage()
          this.ruleForm.vendorAccountList = []
          this.ruleForm.bvaList = []
          this.ruleForm.bvcList = []
        }
        // else if (datas === true) {
        //   this.$message({
        //     type: 'error',
        //     message: '该供应商有报价待审核，不允许变更'
        //   })
        //   this.fordisabled = true
        //   this.clearImage()
        //   this.$refs.ruleForm.resetFields()
        //   this.$refs.ruleForm1.resetFields()
        //   this.ruleForm.vendorChangeStampFileUrl = ''
        //   this.ruleForm.vendorAccountList = []
        //   this.ruleForm.bvaList = []
        //   this.ruleForm.bvcList = []
        //   this.$forceUpdate()
        // }
      }
    },
    // 供应商更改审核校验
    async _getSelectPending(data) {
      const { datas } = await getSelectPending(data)
      this.operateLogData = datas
      if (datas === false) {
        this._baseVendorDetail({ vendorId: this.ruleForm.Id })
      } else {
        this.$message({
          type: 'error',
          message: '已有变更审核中'
        })
        return
      }
    },
    // 供应商保价审核校验
    async _getVendorSelect(data) {
      const { datas } = await getVendorSelect(data)
      this.vendorOptions = datas
    },
    // 获取操作日志
    async _baseVendorDetail(data) {
      try {
        const { datas, datas: { address, account, baseVendorPerson, basic,
          basic: { enterpriseScale, vendorLevel, deliveryMethod, vendorType }, contacts,
          customs: { customsDeclarationType }, payment, payment: { settlementMethod, offerCurrency = '', receivePayCurrency = '' }, id }} = await baseVendorDetail(data)
        this.DetailsDatas = datas
        const { purchaseFeishuUserId } = baseVendorPerson || {}
        const vendorManageEngineerFeishuUserId = []
        const technologyEngineerFeishuUserId = []
        const developEngineerFeishuUserId = []
        const qualityQcEngineerFeishuUserId = []
        this.DetailsDatas?.vendorRoleList?.map(item => {
          const feishuUserName = this.findItemById(this.tagData, item.feishuUserId)?.name
          if (!feishuUserName) return
          if (item.vendorRoleType === 'VENDOR_MANAGE_ENGINEER') {
            vendorManageEngineerFeishuUserId.push(item.feishuUserId)
          }
          if (item.vendorRoleType === 'TECHNOLOGY_ENGINEER') {
            technologyEngineerFeishuUserId.push(item.feishuUserId)
          }
          if (item.vendorRoleType === 'DEVELOP_ENGINEER') {
            developEngineerFeishuUserId.push(item.feishuUserId)
          }
          if (item.vendorRoleType === 'QUALITY_QC_ENGINEER') {
            qualityQcEngineerFeishuUserId.push(item.feishuUserId)
          }
        })
        Object.assign(this.ruleForm, { id }, basic, payment, { developEngineerFeishuUserId, qualityQcEngineerFeishuUserId,
          vendorManageEngineerFeishuUserId, purchaseFeishuUserId, technologyEngineerFeishuUserId },
        { vendorAccountList: account, bvcList: contacts, bvaList: address, enterpriseScale: String(enterpriseScale), vendorLevel: String(vendorLevel),
          deliveryMethod: String(deliveryMethod || ''), settlementMethod: String(settlementMethod), customsDeclarationType: String(customsDeclarationType || ''),
          offerCurrency: offerCurrency && offerCurrency.split(','), receivePayCurrency: receivePayCurrency.split(','), vendorType: String(vendorType)
        }
        )
        this.$nextTick(() => this.$refs.ruleForm.clearValidate())
        address.map(item => {
          this.provinceArr.push(this.province) &&
          this.cityArr.push(this.city) && this.countyArr.push(this.county)
        })
        // 获取地址信息的类型
        this.ruleForm.bvaList ? this.ruleForm.bvaList.map(item => Object.assign(item, { addressTypeList: item.addressTypeList ? item.addressTypeList.map(i => i.addressTypeDict) : [] })) : []
      } finally {
        this.tableDatasLoading = false
      }
    },
    async queryProvinceList() {
      const { datas } = await queryProvinceList()
      this.province = datas
    },
    async queryCityList() {
      const { datas } = await queryCityList()
      this.city = datas
    },
    async queryCountyList() {
      const { datas } = await queryCountyList()
      this.county = datas
    },
    // 省份
    async _queryProvinceList(baseStateId, index) {
      const { datas } = await queryProvinceList({ baseStateId })
      index >= 0 && (this.$set(this.provinceArr, index, datas))
    },
    // 城市
    async _queryCityList(baseProvinceId, index) {
      const { datas } = await queryCityList({ baseProvinceId })
      index >= 0 && (this.$set(this.cityArr, index, datas))
    },
    // 区/县
    async _queryCountyList(baseCityId, index) {
      const { datas } = await queryCountyList({ baseCityId })
      index >= 0 && (this.$set(this.countyArr, index, datas))
    },
    deleteLinkman(item, index) {
      this.ruleForm.bvcList && this.ruleForm.bvcList.splice(index, 1)
    },
    clickBtnLinkman() {
      this.ruleForm.bvcList.push({
        linkman: '',
        role: '',
        mobile: '',
        telephone: '',
        email: '',
        fax: '',
        wechat: '',
        qq: ''

      })
    },
    deleteAccount(item, index) {
      this.ruleForm.vendorAccountList && this.ruleForm.vendorAccountList.splice(index, 1)
    },
    clickBtnAccount() {
      this.ruleForm.vendorAccountList.push({
        accountHolderAddress: '',
        accountName: '',
        accountType: '',
        bankAddress: '',
        bankName: '',
        bankNumber: '',
        currencyType: '',
        swiftCode: '',
        receivePayBankName: ''

      })
    },
    depositRateInput() {
      if (!this.$route.query.id && this.ruleForm.lastRate && this.ruleForm.firstRate) {
        if ((Number(this.ruleForm.lastRate) + Number(this.ruleForm.depositRate) + Number(this.ruleForm.firstRate)) !== 100) {
          this.$message.error('比例之和需为100')
          this.ruleForm.depositRate = ''
        }
      }
    },
    firstRateInput() {
      if (!this.$route.query.id && this.ruleForm.lastRate && this.ruleForm.depositRate) {
        if ((Number(this.ruleForm.lastRate) + Number(this.ruleForm.depositRate) + Number(this.ruleForm.firstRate)) !== 100) {
          this.$message.error('比例之和需为100')
          this.ruleForm.firstRate = ''
        }
      }
    },
    lastRateInput() {
      if (this.ruleForm.firstRate && this.ruleForm.depositRate) {
        if ((Number(this.ruleForm.firstRate) + Number(this.ruleForm.depositRate) + Number(this.ruleForm.lastRate)) !== 100) {
          this.$message.error('比例之和需为100')
          this.ruleForm.lastRate = ''
        }
      }
    },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上三证合一传图片成功时的方法
    brchandleSuccess(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.ruleForm.brc = files.datas
    },
    // 开户许可上传图片成功时的方法
    lfoahandleSuccess(files, filelist) {
      this.ruleForm.lfoa = files.datas
      this.$refs.lfoa.clearValidate()
    },
    // 采购协议上传图片成功时的方法
    pahandleSuccess(files, filelist) {
      this.ruleForm.pa = files.datas
      this.$refs.pa.clearValidate()
    },
    brchandleRemove(file, fileList) {
      this.hideUpload1 = fileList && fileList.length >= this.limitCount1
      this.ruleForm.brc = ''
    },
    // 文件状态改变时的钩子，将加号隐藏
    brchandleFileChange(file, fileList) {
      this.hideUpload1 = fileList && fileList.length >= this.limitCount1
    },
    lfoahandleRemove(file, fileList) {
      this.hideUpload2 = fileList && fileList.length >= this.limitCount2
      this.ruleForm.lfoa = ''
    },
    lfoahandleFileChange(file, fileList) {
      this.hideUpload2 = fileList && fileList.length >= this.limitCount2
    },

    pahandleRemove(file, fileList) {
      this.hideUpload3 = fileList && fileList.length >= this.limitCount3
      this.ruleForm.pa = ''
    },
    pahandleFileChange(file, fileList) {
      this.hideUpload3 = fileList && fileList.length >= this.limitCount3
    },
    // 点击提交按钮将表单数据提交至后台
    handleClick() {
      const { depositRate, firstRate, lastRate } = this.ruleForm
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$refs.ruleForm2.validate(valid => {
            if (valid) {
              this.$refs.ruleForm1.validate(valid => {
                if (valid) {
                  if (Number(depositRate) + Number(firstRate) + Number(lastRate) !== 100) {
                    this.$message.warning('比例之和需为100')
                    return
                  }
                  this._baseVendorSubmit(this.Parameter)
                } else return
              })
            } else return
          })
        } else return
      })
    },
    async _baseVendorSubmit(query) {
      try {
        this.submitLoading = true
        const { msg, code } = await baseVendorSubmit(this.Parameter)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
        } else { // 其他状态
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    },
    stampFileUrlHandleSuccess(files, filelist) {
      this.ruleForm.vendorChangeStampFileUrl = files.datas
      this.$refs.vendorChangeStampFileUrl.clearValidate()
    },

    stampFileUrlHandleRemove(file, fileList) {
      this.hideUploadUrl1 = fileList && fileList.length >= this.limitCountUrl1
      this.ruleForm.vendorChangeStampFileUrl = ''
    },
    // 文件状态改变时的钩子，将加号隐藏
    stampFileUrlHandleFileChange(file, fileList) {
      this.hideUploadUrl1 = fileList && fileList.length >= this.limitCountUrl1
    },
    goBack() {
      this.$router.go(-1)
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      const isLt5M = file.size / 1024 / 1024 < 5
      const isApk = name[name.length - 1] === 'png' || name[name.length - 1] === 'jpg' || name[name.length - 1] === 'jpeg' || name[name.length - 1] === 'pdf'
      if (!isApk) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '请上传jpg/png/jpeg格式图片或pdf文件，大小不超过5M'
        })
      }
      if (!isLt5M) {
        this.$message.error('上传图片或文件大小不能超过 5MB!')
      }
      return isApk && isLt5M
    },
    cascaderChange(val, ref) {
      if (val && val.length === 0) {
        const _cascader = this.$refs[ref]
        if (_cascader) {
          _cascader.$refs.panel.checkedValue = [] // 清空选中值
          _cascader.$refs.panel.clearCheckedNodes() // 清空级联选择器选中状态
          _cascader.$refs.panel.activePath = [] // 清除高亮
          _cascader.$refs.panel.syncActivePath() // 初始化（只展示一级节点）
        }
      }
    }
  }
}
</script>
<style>
.hide .el-upload--picture-card {
  display: none;
}
.bvcListInput .el-input__inner {
    width:120px!important;
}
.vendorColor .el-form-item__label{
  color:red;
  font-size:16px
}
</style>

